<template>
  <section class="content">
    <table class="table table-hover" ref="tblkelas">
      <thead>
        <tr>
          <th>NAMA PROGRAM</th>
          <th>JENIS</th>
          <th>DESKRIPSI</th>
          <th>BIAYA</th>
          <th>PERIODE</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="control-label">Nama Program</label>
        <input
          id="program"
          class="form-control"
          v-model="form.name"
          type="text"
          required="required"
          name="program"
        />
      </div>
      <div class="col-md-6 form-group">
        <div class="col-5">
          <label for="jenis_program" class="control-label">Jenis Program</label>
        </div>
        <div class="form-check form-check-inline ml-2">
          <input
              class="form-check-input"
              type="radio"
              name="jenis_program"
              id="jenis_program"
              v-model="form.jenis_program"
              value="1"
          />
          <label class="form-check-label" for="jenis_program">Online</label>
        </div>
        <div class="form-check form-check-inline">
          <input
              class="form-check-input"
              type="radio"
              name="jenis_program"
              id="jenis_program"
              v-model="form.jenis_program"
              value="0"
          />
          <label class="form-check-label" for="jenis_program">Offline</label>
        </div>
      </div>
      <div class="col-md-6 form-group">
        <label class="control-label">Deskripsi</label>
        <textarea
          id="deskripsi"
          class="form-control"
          v-model="form.deskripsi"
          type="text"
          name="deskripsi"
          rows="15"
        />
        <span style="color: red;"> *) gunakan enter untuk memisahkan baris </span>
      </div>
      <div class="col-md-6 form-group">
        <label class="control-label">Biaya</label>
        <input
          id="harga"
          class="form-control"
          v-model="form.harga"
          type="number"
          required="required"
          name="harga"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="control-label">Periode</label>
        <input
          id="periode"
          class="form-control"
          v-model="form.periode"
          type="text"
          placeholder="contoh: 2021"
          name="periode"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="control-label"></label>
        <input
          id="active"
          v-model="form.active"
          type="checkbox"
          name="active"
        />
        <label for="active">Active</label>
      </div>
    </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Program",
      form: {
        active: "",
        name: "",
        deskripsi: "",
        harga: "",
        periode: "",
        jenis_program: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/akademik/data_induk_program";
      if (this.method == "PUT")
        urlSubmit = "/akademik/data_induk_program/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/akademik/data_induk_program",
      columns: [
        { data: "name" },
        { data: "jenis_program",
        render: function (data, type, row, meta) {
          if (data > 0) {
            return '<span style="font-size:100%" class="badge badge-info">ONLINE</span>';
          } else {
            return '<span style="font-size:100%" class="badge badge-info">OFFLINE</span>';
          }
        }, },
        { data: "deskripsi" },
        { data: "harga",
        render: function (data, type, row, meta) {
          return "Rp " + formatCurrency(data);
        }, },
        { data: "periode" },
        { data: "active" },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        if (data.active == "t")
          $("td:eq(5)", row).html(
            '<span class="badge badge-success">Active</span>'
          );
        else if (data.active == "f")
          $("td:eq(5)", row).html(
            '<span class="badge badge-warning">Not Active</span>'
          );
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Program";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          //jika active == 0 return false, jika active == 1 return true
          if (evt.data.active == "t") {
            self.form.active = true;
          } else if (evt.data.active == "f") {
            self.form.active = false;
          }
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Program";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/akademik/data_induk_program/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
